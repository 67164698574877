import { defineAsyncComponent } from "vue";
export const Avatar3 = defineAsyncComponent(() =>
  import("./components/avatar")
);
export const Icon3 = defineAsyncComponent(() => import("./components/icon"));
export const Button3 = defineAsyncComponent(() =>
  import("./components/button")
);
export const Spinner3 = defineAsyncComponent(() =>
  import("./components/spinner")
);
export const Link3 = defineAsyncComponent(() => import("./components/link"));
export const Radio3 = defineAsyncComponent(() => import("./components/radio"));
export const RadioButon3 = defineAsyncComponent(() =>
  import("./components/radio/RadioButton.vue")
);
export const Tag3 = defineAsyncComponent(() => import("./components/tag"));
